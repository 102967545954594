.custom-shape-divider-bottom {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(180deg);
  z-index: -1;
}

.custom-shape-divider-bottom svg {
  position: relative;
  display: block;
  width: calc(100%);
  height: 600px;

}

.custom-shape-divider-bottom .shape-fill {
  fill: url(#gradient);
}

.custom-shape-divider-top {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  z-index: -1;
}

.custom-shape-divider-top svg {
  position: relative;
  display: block;
  width: calc(100%);
  height: 600px;
}

.custom-shape-divider-top .shape-fill {
  fill: url(#gradient);
}


