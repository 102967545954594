.sliderContainer {
	/* position: absolute; */
	padding:1em;
	background: white;
	max-width: 15em;
	border-radius: 5px;
	box-shadow: 0px 7px 13px -4px rgba(114, 114, 114, 0.2);
	z-index: 20;
	animation: slide-left 500ms ease;
	position: fixed;
  }
  .left {
	position: fixed;
	left:10%;
  }

  .rigth {
	position: fixed;
	right:10%;
  }

  @media only screen and (min-width: 1024px) {
	.left {
		left:20%;
	  }
	
	  .rigth {
		right:20%;
	  }

}
@media only screen and (min-width: 1440px) {
	.left {
		left:30%;
	  }
	
	  .rigth {
		right:30%;
	  }

}
@media only screen and (min-width: 2560px) {
	.left {
		left:40%;
	  }
	
	  .rigth {
		right:40%;
	  }

}

  .triangle {
	/* position: absolute; */
	width: 20px;
	z-index: 99;
	animation: slide-left 500ms ease;
	border-bottom: solid 15px white;
	border-left: solid 15px transparent;
	border-right: solid 15px transparent;
	position: relative;	
	left:0;
	right:0;

  }
  
  
  .detailIcon {
	width: 1em;
	margin: auto 1em;
  }
  

  
  @keyframes slide-left {
	0% {
	  opacity: 0;
	}
	100% {
	  opacity: 1;
	}
  }
  
  @keyframes slide-right {
	0% {
	  opacity: 0;
	}
	100% {
	  opacity: 1;
	}
  }
  

  
  