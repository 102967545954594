.sliderContainer{
    position: fixed;
    top:20%;
    left:0;
    right: 0;
    max-width: 90vw;
    max-height: 80vh;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin:auto;
    background: white;
    border-radius: 15px; 
    box-shadow: 0px 7px 13px -4px rgba(114, 114, 114, 0.2);
    z-index: 99;
     animation: slide-bottom 500ms ease;
      
  }
  .innerContainer{
    width: 100%;
    height: auto;
    min-height:20vh;
    z-index: 10;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .backgroundBlur{
    position: fixed;
    width: 100%;
    height: 100vh;
    top:0;
    left:0;
    right: 0;
    bottom:0;
    backdrop-filter: blur(5px);
    background-color: #2e2e5894;
    z-index: 99;
    transition: 500ms all;
    animation: slide-right 300ms ease;
    display: flex;
    align-items: center;
    justify-content: center;
    margin:auto;
  }
  
  .detailIcon{
      width:1em;
      margin:auto 1em;
  }
  
  .closeButton {
    position: absolute;
    left:1em;
    top:1em;
    width: 1.5em;
    transition: 300ms;
    z-index: 20;
  
  }
  
    .closeButton:hover {
      cursor: pointer;
      transform: scale(1.2);
    }
    
    .closeButton:active {
      cursor: pointer;
      transform: scale(1.1);
      filter: opacity(0.7);
    }
  
  
    @keyframes slide-right {
      0% {
        opacity: 0;
        transform: translateX(200%);
      }
      100% {
        opacity: 1;
        transform: translateX(0%);
      }
    }
  
    @keyframes slide-right {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
    
    @keyframes slide-bottom {
      0% {
        transform: translateY(-200%);
      }
      100% {
        transform: translateY(0%);
      }
    }
  