@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Barlow:ital,wght@0,400;0,500;0,700&display=swap");
:root {
  /* Primary */
  --white: #ffffff;
  --green99: #85c440;
  --dark: #262927;
  --gray100: #9697af;
  --gray50: #bfbfbf;
  --gray25: #e5e5e5;
  --gray10: #f7f7f7;
  /* Secondary */
  --lime: #abdb40;
  --mint: #3fc468;
  --green: #66d02d;
  --batman: #12344a;
  --red: #cf3729;
  --redLight: #f3594b;
  --minty: #3fc468;
  --yellow: #f1ca48;
}

#root {
  width: 100vw;
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: "Barlow", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--dark);
  display: flex;
  overflow-x: hidden;
}
html {
  height: 100%;
}
body {
  min-height: 100vh;
  width: 100%;
}

#__next {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.App {
  height: 100vh;
}

/* Gradients */
.gradient-1 {
  background: linear-gradient(to bottom, var(--green99), var(--green));
}

.gradient-2 {
  background: linear-gradient(to bottom, var(--green99), var(--mint));
}

/* colors classes */

.color-white {
  color: white;
}

.color-green99 {
  color: var(--green99);
}

.color-dark {
  color: var(--dark);
}

.color-gray100 {
  color: var(--gray100);
}

.color-gray50 {
  color: var(--gray50);
}

.color-gray25 {
  color: var(--gray25);
}

.color-lime {
  color: var(--lime);
}

.color-mint {
  color: var(--mint);
}

.color-green {
  color: var(--green);
}

.color-batman {
  color: var(--batman);
}

.color-yellow {
  color: rgb(223, 238, 8);
}

/*---------  Subsection Typography  ---------*/

/*---Font weights table----
-     bold:       700     -
-     semi bold:  500     -
-     normal:     400     -
--------------------------*/

h1,
.h1 {
  font-size: 32px;
  font-weight: 700;
}

h2,
.h2 {
  font-size: 30px;
  font-weight: 700;
}

h3,
.h3 {
  font-size: 28px;
  font-weight: 700;
}

h4,
.h4 {
  font-size: 26px;
  font-weight: 700;
}

h5,
.h5 {
  font-size: 24px;
  font-weight: 700;
}

h6,
.h6 {
  font-size: 22px;
  font-weight: 700;
}

p,
.p {
  font-size: 13px;
  font-weight: 400;
}

small,
.small {
  font-size: 10px;
  font-weight: 400;
}

.subtitle1 {
  font-size: 20px;
  font-weight: 700;
}

.subtitle2 {
  font-size: 18px;
  font-weight: 700;
  margin: 0px;
}

.body1 {
  font-size: 19px;
  font-weight: 400;
}

.body2 {
  font-size: 17px;
  font-weight: 400;
}

.button-text {
  font-size: 16px;
  font-weight: 500;
}

.caption-text {
  font-size: 14px;
  font-weight: 400;
}

.card-text {
  font-size: 14px;
}
.bold {
  font-weight: 700;
}

.semi-bold {
  font-weight: 500;
}
.normal {
  font-weight: 400;
}

.body3 {
  font-size: 18px;
}

/*=============================================
=                Components Section               =
=============================================*/
/*--=--------  Terms  -----------*/
.terms{
  text-align: left;
  padding: 0 10%;
}
.terms p {
  font-weight: 500;
}
/*--=--------  Dividerss -----------*/

.divider {
  height: 1.2px;
  background: #f0f0f0;
}

/*--=--------  Rounded pic -----------*/

.rounded-pic {
  border-radius: 50%;
  object-fit: cover;
  margin: 0.5em;
}
.rounded-btn {
  border-radius: 50%;
  object-fit: cover;
}

/*--=--------  Basic Icon -----------*/

.icon {
  width: 1em;
  height: 1em;
  margin-right: 0.5em;
}

/*--=--------  Input -----------*/

.input-label {
  color: var(--batman);
  margin-bottom: 0.65em;
}

.input {
  width: 100%;
  background-color: transparent;
}
.input::placeholder {
  color: var(--gray50);
  padding-left: 1.4em;
}
.input:active {
  color: var(--gray50);
  padding-left: 1.4em;
}

.input-underline-gray {
  border: none;
  border-bottom: 1.5px solid var(--gray25);
}
.input-underline-gray::placeholder {
  color: var(--gray25);
}

.input-underline {
  background: transparent;
  width: 100%;
  border: none;
  border-bottom: 1.5px solid var(--white);
  color: white;
}
.input-underline::placeholder {
  color: white;
}

.input-border {
  border: 1.5px solid var(--gray25);
}

.input-none {
  border: none;
  background-color: transparent;
}

.inputRadio {
  font-size: 13px;
  color: var(--batman);
  padding-left: 0.5em;
}

.inputRadioContainer {
  width: 50%;
}


.inputOrders::placeholder {
  color: var(--gray50);
}

.search_reference{
  display: flex;
  justify-content: end;
  background: url("./assets/icons/icon_search.svg") no-repeat;
  background-size: 20px;
  display: inline-block;
  background-position: left 95% bottom 50%;
  /* vertical-align: end; */
}

/*=====   Error  Input  ======*/

.error {
  border: 1.5px solid var(--red);
  position: relative;
  animation: shake .1s linear;
  animation-iteration-count: 3;
}

@keyframes shake {
  0% { left: -5px; }
  100% { right: -5px; }
}

/*--=--------  Input for schedule -----------*/

.inputSchedule {
  color: var(--gray100);
  text-align: center;
  border-radius: 5px;
  font-size: 13px;
  width: 7em;
  background-color: transparent;
}
/* Remove clock */
input[type="time"]::-webkit-calendar-picker-indicator {
  background: none;
  display: none;
}

.inputSchedule::placeholder {
  color: var(--gray100);
  font-size: 14px;
}
.inputSchedule:focus {
  color: var(--batman);
}

input:required {
  border-color: #d40606;
  border-width: 1.5px;
}

/*--=--------  IconBtn -----------*/

.btn-unselected {
  border: none;
  background: none;
  color: var(--gray50);
}

.btn-plain {
  border: none;
  background: none;
  color: var(--batman);
}

.btn-shadow {
  border-radius: 5px;
  border: none;
  background: white;
  padding: 0.8em 1em;
  box-shadow: 0px -1px 21px -3px rgba(151, 151, 151, 0.25);
}

.btn-shadow:hover {
  border: none;
  background: white;
  padding: 0.8em 1em;
  box-shadow: 0px -1px 21px -3px rgba(110, 110, 110, 0.3);
}
/*=====      End of Component Section     ======*/

/*=============================================
=                Breakpoints              =
=============================================*/

/*----------  Subsection buttons  -----------*/

.agave-button {
  height: 47px;
  font-size: 16px;
  font-weight: 500;
  padding: 0 15px;
  border: unset;
  border-radius: 50px;
  transition: all 200ms;
}


.agave-button-thin {
  height: 30px;
  font-size: 13px;
  font-weight: 500;
  padding: 0 32px;
  border: unset;
  border-radius: 50px;
  transition: all 200ms;
}

/* Primary */

.ag-button-primary {
  background: var(--green99);
  color: white;
}

.ag-button-primary:hover {
  cursor: pointer;
  background: var(--mint);
  background-position: 100px;
}

.ag-button-primary:active {
  background: var(--lime);
}

.ag-button-primary:disabled,
.ag-button-primary[disabled] {
  background: var(--gray25);
  color: var(--gray50);
}

.ag-button-primary:disabled:hover,
.ag-button-primary[disabled]:hover {
  cursor: not-allowed;
}

/* Secondary */

.ag-button-secondary {
  box-shadow: inset 0 0 0 2px var(--green);
  color: var(--batman);
  transition: all 200ms;
}

.ag-button-secondary:hover {
  box-shadow: inset 0 0 0 2px var(--mint);
  color: var(--batman);
  cursor: pointer;
}

.ag-button-secondary:active {
  box-shadow: inset 0 0 0 2px var(--green99);
  color: var(--batman);
}

.ag-button-secondary:disabled,
.ag-button-secondary[disabled] {
  box-shadow: inset 0 0 0 2px var(--gray50);
  color: var(--gray50);
}

.ag-button-secondary:disabled:hover,
.ag-button-secondary[disabled]:hover {
  cursor: not-allowed;
}

/* white */

.ag-button-white {
  background: white;
  color:  var(--green99);
}

.ag-button-white:hover {
  cursor: pointer;
  background: whitesmoke;
  background-position: 100px;
}

.ag-button-white:active {
  background: whitesmoke;
}

.ag-button-white:disabled,
.ag-button-white[disabled] {
  background: var(--gray50);
  color: gray;
}

.ag-button-white:disabled:hover,
.ag-button-white[disabled]:hover {
  cursor: not-allowed;
}
/*--=--------  Edit user info page -----------*/
.upperSection {
  height: 25vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.backArrow {
  position: absolute;
  top: 2em;
  left: 1em;
}

.downSection {
  height: 10vh;
  bottom: 0;
}

.form {
  border-radius: 10px;
  width: 90%;
  min-height: 20vh;
  background-color: white;
  box-shadow: 0px -1px 21px -3px rgba(151, 151, 151, 0.35);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  position: relative;
  bottom: 15%;
}
.map {
  height: 30vh;
  width: 100%;
  object-fit: cover;
}

.imagesScroll {
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  overflow-x: scroll;
}

/*--=--------  Onboarding page -----------*/

.upperSectionOnboarding {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.carousel-indicators {
  display: none !important;
}

.vi__wrapper {
  width: 100% !important;
}

.mainImage {
  height: max-content;
  width: 85vw;
  position: absolute;
  top: 25vh;
  margin: auto;
}

.mainIcon {
  height: 20vw;
  width: 20vw;
  position: absolute;
  top: 18em;
  margin: auto;
}

.titleSection {
  text-align: center;
  position: absolute;
  top: 6vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.upperSectionOnboardingPhone {
  height: 30vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}

.titleSectionPhone {
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 90%;
}

.currentSlider {
  width: 4vw;
  height: 2vw;
  border-radius: 38%;
  background-color: rgb(151, 106, 182);
}
.unactiveSlider {
  width: 2vw;
  height: 2vw;
  border-radius: 50%;
  background-color: rgb(228, 228, 228);
}

.jumpBtn {
  border: none;
  color: var(--gray50);
  background-color: transparent;
  font-size: 18px;
  font-weight: 500;
}

.inoutPhone {
  background-color: white;
  min-width: 14em;
  max-width: 20em;
  padding: 0.35em;
  padding-left: 1.4em;
  color: var(--batman);
  font-weight: 500;
}
.inoutPhone::placeholder {
  background-color: white;
  color: var(--batman);
  font-weight: 500;
  padding-left: 1.4em;
}

.container {
  height: 50px;
  width: 100%;
}

@media (min-width: 0px) {
  .container {
    max-width: 100%;
  }
}

.character {
  line-height: 50px;
  font-size: 36px;
  color: white;
  min-width: 1em;
  max-width: 1.5em;
  background-color: rgba(236, 236, 236, 0.671);
  border: 1px solid transparent;
  border-radius: 8px;
  margin-left: 8px;
}

.character:first-child {
  margin-left: 0;
}

.character--inactive {
  background-color: rgba(255, 255, 255, 0.521);
}

.character--selected {
  border: 1px solid white;
}

/*--=--------  HomePage -----------*/
.profitCardContainer {
  padding: 2em 1em 0.5em 1em;
  background-color: white;
  border-radius: 5px;
  box-shadow: -3px 8px 21px 5px rgb(121 121 121 / 25%);
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 13em ;

}
.profitCardIcon {
  width: 3.5em;
  height: 3.5em;
  padding: 0.7em;
  object-fit: contain;
  box-shadow: 0px -1px 21px -3px rgba(151, 151, 151, 0.25);
  background-color: white;
  position: absolute;
  z-index: 1;
  top: -2em;
}
.siderMenuIcon {
  width: 2em;
  height: 1.8em;
  object-fit: contain;
  border-radius: 50%;
  margin-right: 2em;
}

.siderMenuIconClose {
  width: 2em;
  height: 2em;
  object-fit: contain;
  margin-right: 2em;
}

.sliderMenu {
  z-index: 10;
  position: absolute;
  top: 0;
  left: 0;
  animation: slide-right 400ms ease;
}

.downSection {
  border-radius: 30% 30% 00% 00%;
}

.btn-border-white {
  padding: 0.75em 0.5em;
  background-color: transparent;
  border: 1.2px solid white;
  color: white;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  line-height: 13px;
}

.three-dots {
  background: none;
  border: none;
  padding: 0px;
  cursor: pointer;
}

.outside-modal {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 2;
}

.three-dots-modal {
  background: white;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  right: 1.9em;
  top: 6em;
  z-index: 4;
  width: max-content;
  border-radius: 4px;
  box-shadow: 0px -1px 21px -3px rgba(151, 151, 151, 0.4);
}

.button-modal {
  width: 100%;
  padding: 0px 2em;
  height: 3.4em;
  z-index: 5;
  border-radius: 03px;
  background: white;
  border: none;
  cursor: pointer;
  color: var(--gray50);
  display: flex;
  font-size: 16px;
}

.button-modal:hover {
  background: var(--gray25);
  border-radius: 0px;
}

.buttonLegal {
  border: none;
  background: none;
}
.bg-transparent {
  color: black;
  background-color: gray;
}

/*--=--------  Profit page -----------*/

.recharts-text {
  fill: white !important;
  font-weight: bold;
}
.recharts-layer {
  width: 100%;
}

.speech {
  font-size: 1em;
  color: #399ebd;
  position: relative;
  background: white;
  padding: 10px;
  border-radius: 5px;
  max-width: 320px;
  font-weight: bold;
}

/*=====   Not found page    ====== */
.containerNotFound {
  width: 100vw;
  height: 100vh;
  background: linear-gradient(to bottom, var(--green99), var(--mint));
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.goBack {
  text-decoration: underline;
  color: white;
}

/*--=--------  Splash screen page -----------*/

.cirleLogo {
  height: 17em;
  width: 17em;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 30vh;
  left: 0;
  right: 0;
  margin: auto;
  background-color: white;
  border-radius: 50%;
  box-shadow: 0px -1px 21px -3px rgba(151, 151, 151, 0.35);
}

.mainlogo {
  height: 100%;
  width: 13em;
  position: relative;
  margin: auto;
}
.change-exit {
  opacity: 1;
}
.change-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 3000ms, transform 300ms;
}

@keyframes appear {
  0% {
    display: none;
    opacity: 0;
}

10% {
    display: block;
    opacity: 0;
}
  100% {
    opacity: 1;
  }
}
@keyframes disappear {
  0% {
    opacity: 1;
}
  100% {
    opacity: 0;
    display: none;

  }
}

.title-animation{
  animation: appear 3s;

}
.title-animation-out{
  animation: disappear 0.5s;

}


.returnPackageVeloz {
  background-color: white;
  border-radius: 5px;
  box-shadow: 0px -1px 21px -3px rgba(151, 151, 151, 0.25);
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: 1em auto;
}
.returnPackageClient {
  background-color: white;
  border-radius: 5px;
  box-shadow: 0px -1px 21px -3px rgba(151, 151, 151, 0.25);
  display: flex;
  align-items: center;
  margin: 1em auto;
}

    /* FAQ btn  */

    .faqBtn {
      border: 2px solid white;
      border-radius: 40px;
      color: var(--batman);
      padding: 6px 10px;
      font-weight: 600;
      font-size: 12px;
    }

@media only screen and (min-width: 760px) {
  .mainImage {
    height: max-content;
    width: 15em;
    position: absolute;
    top: 25vh;
    margin: auto;
  }
}

/* @media only screen and (max-heigth: 480px) {
  .mainImage {
    height: 20vh;
    width: 15em;
    position: absolute;
    top: 25vh;
    margin: auto;
  }
} */

@media only screen and (max-width: 360px) {
  .payment-container {
    height: 25%;
  }


}
@media only screen and (min-width: 361px) {
  .payment-container {
    height: 40%;

  }
}
/*=====   large screens     ====== */

@media only screen and (min-width: 1280px) {
  .mainImage {
    height: max-content;
    width: 18em;
    position: absolute;
    top: 25vh;
    margin: auto;
  }

  .currentSlider {
    width: 2vw;
    height: 1vw;
  }
  .unactiveSlider {
    width: 1vw;
    height: 1vw;
    border-radius: 50%;
  }
  .form {
    width: 30em;
  }
  .map {
    max-width: 50em;
    margin: auto;
  }
  .imagesScroll {
    overflow-x: hidden;
  }
}
/*=====    large screens     ====== */

.Toastify__toast-body {
  white-space: pre-line;
}
/* --=-------- extra big screens ----------- */

@media only screen and (min-width: 2800px) {
  .mainImage {
    height: 25vh;
    width: 30vw;
    top: 16em;
  }
  .currentSlider {
    width: 1vw;
    height: 0.5vw;
  }
  .unactiveSlider {
    width: 0.5vw;
    height: 0.5vw;
    border-radius: 50%;
    background-color: rgb(228, 228, 228);
  }

  .upperSectionOnboardingPhone {
    height: 20vh;
  }

  .btn-border-white {
    border: 1.2px solid var(--gray50);
    color: var(--gray50);
  }

  .imagesScroll {
    overflow-x: hidden;
  }
}

/*=====    end  big screens     ====== */

/*=====      Animations slider     ======*/

@keyframes slide-right {
  0% {
    opacity: 0;
    transform: translateX(200%);
  }
  100% {
    opacity: 1;
    transform: translateX(0%);
  }
}

.gradient-green {
  background-image: linear-gradient(to bottom, #85c440, #00d2b6);
}

/*=====      packages Page: Animations slideup slider     ======*/
.slide-open {
  transform: translate3d(0, 0%, 0);
}

.slide-close {
  transform: translate3d(0, 100%, 0);
  position: fixed;
  bottom: 0;
}
.tab-type {
  background-color: white;
  border: 1px solid var(--gray25);
  font-weight: bold;
  font-size: 12.5px;
}
.tab-type-active {
  background-color: var(--green99);
  color:white;
}

.tab-type-unactive {
  background-color: var(--gray10);
  color:var(--gray100);

}
/*=====      Animations slideup slider     ======*/

.lds-dual-ring {
  display: inline-block;
  width: 80px;
  height: 80px;
}
.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid var(--green99);
  border-color: var(--green99) transparent var(--green99) transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}

.lds-dual-ring-small {
  display: flex;
  justify-content: center;
  align-items: center;
  width: calc(40px / 3 * 2);
  height: calc(40px / 3 * 2);
}
.lds-dual-ring-small:after {
  content: " ";
  display: block;
  width: calc(32px / 3 * 2);
  height: calc(32px / 3 * 2);
  margin: 4px;
  border-radius: 50%;
  border: 3px solid var(--green99);
  border-color: var(--green99) transparent var(--green99) transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
.lds-dual-ring-white {
  display: inline-block;
  width: 80px;
  height: 80px;
}
.lds-dual-ring-white:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid white;
  border-color: white transparent white transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}

.lds-dual-ring-transparent {
  display: inline-block;
  width: 80px;
  height: 80px;
}
.lds-dual-ring-transparent:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid white;
  border-color: rgba(255, 255, 255, 0.482) transparent rgba(255, 255, 255, 0.482)  transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}


.lds-dual-ring-small-white {
  display: flex;
  justify-content: center;
  align-items: center;
  width: calc(40px / 3 * 2);
  height: calc(40px / 3 * 2);
}
.lds-dual-ring-small-white:after {
  content: " ";
  display: block;
  width: calc(32px / 3 * 2);
  height: calc(32px / 3 * 2);
  margin: 4px;
  border-radius: 50%;
  border: 3px solid white;
  border-color: white transparent white transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/*=============================================
=             Switch Input Section            =
=============================================*/

.switch {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 18px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}




.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 1px;
  bottom: 1px;
  background-color: white;
  transition: 0.4s;
}

.switch input:checked + .slider {
  background-color: #3fc468;
}

.switch input:focus + .slider {
  box-shadow: 0 0 1px #3fc468;
}

.switch input:checked + .slider:before {
  -webkit-transform: translateX(12px);
  -ms-transform: translateX(12px);
  transform: translateX(12px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 25px;
}

.slider.round:before {
  border-radius: 50%;
}

/*=====    /*geosuggestion   ======*/

.geosuggest {
  font-size: 18px;
  font-size: 1rem;
  position: relative;
  margin: 0.2em auto;
  text-align: left;
  color: black;
  width: 100%;
  padding-left: 1.8em;
}
.geosuggest__input {
  width: 100%;
  border-bottom: 0.7px solid var(--gray25);
}
.geosuggest__input::placeholder {
  width: 100%;
  color: var(--gray50);
}

.geosuggest__input:focus {
  /* border-color: #acacac; */
  box-shadow: 0 0 0 transparent;
}
.geosuggest__suggests {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  max-height: 25em;
  padding: 0;
  margin-top: -1px;
  background: #fff;
  border: 1px solid var(--gray100);
  border-top-width: 0;
  overflow-x: hidden;
  overflow-y: auto;
  list-style: none;
  z-index: 5;
  -webkit-transition: max-height 0.2s, border 0.2s;
  transition: max-height 0.2s, border 0.2s;
}
.geosuggest__suggests--hidden {
  max-height: 0;
  overflow: hidden;
  border-width: 0;
}

/**
   * A geosuggest item
   */
.geosuggest__item {
  font-size: 18px;
  font-size: 1rem;
  padding: 0.5em 0.65em;
  cursor: pointer;
}
.geosuggest__item:hover,
.geosuggest__item:focus {
  background: #f5f5f5;
}
.geosuggest__item--active {
  background: #267dc0;
  color: #fff;
}
.geosuggest__item--active:hover,
.geosuggest__item--active:focus {
  background: #ccc;
}
.geosuggest__item__matched-text {
  font-weight: bold;
}
/*=====  end  /*geosuggestion   ======*/

/* support widget  */

.supportWidget {
  background-color: #85c440 !important;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 0px 3px;
}

.textSupportWidget {
  color: #fff;
  font-weight: 600;
}

/*=====      Pagination     ======*/

.pagination {
  border: 1px solid rgba(237, 242, 249, 1);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background-color: white;
  width: 100%;
  font-size: 18px;
  margin-top: 2em;
  display: flex;
  list-style: none;
  outline: none;
  justify-content: space-between;
  color: var(--gray25);
  padding-right: 8.333333%;
  padding-left: 8.333333%;
  /* padding-top: 3px; */
  /* border-top: 2px solid var(--gray25); */
}
.pagination > .active {
  width: fit-content;
}
.pagination > .active > a {
  color: var(--green);
}
.pagination > li > a {
  padding: 5px 10px;
  outline: none;
  cursor: pointer;
}
.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
  outline: none;
  color: var(--green);
  font-weight: 700;
}
.pagination > li > a,
.pagination > li > span {
  color: var(--gray50);
}


.paginationPayments {
  width: 100%;
  font-size: 18px;
  display: flex;
  list-style: none;
  outline: none;
  justify-content: space-between;
  color: var(--gray50);
  padding: 20px 0px;
}
.paginationPayments > .active > a {
  border-top: 2px solid var(--green);
  color: var(--green);
}
.paginationPayments > li > a {
  padding: 0px 10px;
  outline: none;
  cursor: pointer;
}
.paginationPayments > .active > a,
.paginationPayments > .active > span,
.paginationPayments > .active > a:hover,
.paginationPayments > .active > span:hover,
.paginationPayments > .active > a:focus,
.paginationPayments > .active > span:focus {
  border-top: 2px solid var(--green);
  outline: none;
  color: var(--green);
  font-weight: 700;
}
.paginationPayments > li > a,
.paginationPayments > li > span {
  color: var(--gray50);
}


/*=====   End of Pagination     ======*/


/*=====      PaginationBot     ======*/

.pagination-bottom {
  border: 1px solid rgba(237, 242, 249, 1);
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  background-color: white;
  width: 100%;
  font-size: 18px;
  display: flex;
  list-style: none;
  outline: none;
  justify-content: space-between;
  color: var(--gray25);
  padding-right: 8.333333%;
  padding-left: 8.333333%;
}
.pagination-bottom > .active {
  width: fit-content;
}
.pagination-bottom > .active > a {
  color: var(--green);
}
.pagination-bottom > li > a {
  padding: 5px 10px;
  outline: none;
  cursor: pointer;
}
.pagination-bottom > .active > a,
.pagination-bottom > .active > span,
.pagination-bottom > .active > a:hover,
.pagination-bottom > .active > span:hover,
.pagination-bottom > .active > a:focus,
.pagination-bottom > .active > span:focus {
  outline: none;
  color: var(--green);
  font-weight: 700;
}
.pagination-bottom > li > a,
.pagination-bottom > li > span {
  color: var(--gray50);
}

/*=====   End of Pagination bot    ======*/


.content {
  position:relative;
}
.content:before, .content:after, .content>:first-child:before, .content>:first-child:after {
    position:absolute;
    width:7vh;
    height: 7vh;
    border-color:black; /* or whatever colour */
    border-style:solid; /* or whatever style */
    content: ' ';
    z-index: 2;
}
.content:before {
  top:0;
  left:0;
  border-width: 7px 0 0 7px;
}
.content:after {
  top:0;
  right:0;
  border-width: 7px 7px 0 0;
}
.content>:first-child:before {
  bottom:0;
  right:0;
  border-width: 0 7px 7px 0;
  z-index: 2;
}
.content>:first-child:after {
  bottom:0;
  left:0;
  border-width: 0 0 7px 7px;
  z-index: 2;
}

@media only screen and (min-height: 500px) {
  .content:before, .content:after, .content>:first-child:before, .content>:first-child:after {
    width:5vh;
    height: 5vh;
  }
}